@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Press Start 2P", cursive;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  position: fixed;
  width: 100vw;
  z-index: 100;
  gap: 1rem;
  top: 0;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  list-style: none;
}

nav a {
  color: var(--textColor);
  text-decoration: none;
}

nav a:hover {
  color: var(--secondairyColor);
}

.nav-btn {
  background: none;
  border: none;
  color: var(--textColor);
  font-size: 2rem;
  cursor: pointer;
  display: none;
  z-index: 10000;
}

header h3 {
  z-index: 10000;
}

@media only screen and (max-width: 1024px) {
  .nav-btn {
    display: block;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    position: fixed;
    width: 100%;
    top: 80px; /* Hoogte van de header */
    bottom: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateY(-120%);
  }

  .responsive_nav ul {
    transform: translateY(0);
  }

  nav a {
    font-size: 1.5rem;
  }
}

#chat {
  top: 4000px;
}
