
.contact-page{
    box-shadow: 1px 1px 3px 2px white;
display: block;
background-color: black;
margin: 200px auto;
max-width: 500px;
max-height: 500px;
padding: 20px;




}

.contact-page input, textarea{
display: flex;
flex-direction: column; 
width: 100%;
margin: 10px auto;
padding: 10px;
border: 1px solid white
}

.message{
color: black;

}

.contact-page span{
color:#ff4500 ;


}

/* Voeg deze stijlregels toe aan je contact.css-bestand of een ander CSS-bestand waar je de stijlen definieert */

.alert {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  color: #333;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.alert-error {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
