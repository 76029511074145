@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Press Start 2P", cursive;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  position: fixed;
  width: 100vw;
  z-index: 100;
  grid-gap: 1rem;
  gap: 1rem;
  top: 0;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 4rem;
  gap: 4rem;
  list-style: none;
}

nav a {
  color: var(--textColor);
  text-decoration: none;
}

nav a:hover {
  color: var(--secondairyColor);
}

.nav-btn {
  background: none;
  border: none;
  color: var(--textColor);
  font-size: 2rem;
  cursor: pointer;
  display: none;
  z-index: 10000;
}

header h3 {
  z-index: 10000;
}

@media only screen and (max-width: 1024px) {
  .nav-btn {
    display: block;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    background-color: var(--mainColor);
    position: fixed;
    width: 100%;
    top: 80px; /* Hoogte van de header */
    bottom: 0;
    left: 0;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    -webkit-transform: translateY(-120%);
            transform: translateY(-120%);
  }

  .responsive_nav ul {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  nav a {
    font-size: 1.5rem;
  }
}

#chat {
  top: 4000px;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
  background-color: #150704;
  background-color: var(--backgroundColor);
  padding: 0%;
  overflow-x: hidden;
}

:root {
  --mainColor: #33130c;
  --mainColorLight: #59261b;
  --secondairyColor: #ff3608;
  --textColor: white;
  --backgroundColor: #150704;
}

/* GAMEPLAYER */

@media screen and (min-width: 768px) {
  .game-container {
  }
}

/* Classes */

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

button {
  background-color: #59261b;
  background-color: var(--mainColorLight);
  color: white;
  color: var(--textColor);
  border: none;
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
}

button:hover {
  background-color: #ff3608;
  background-color: var(--secondairyColor);
  color: white;
  color: var(--textColor);
}

input {
  background-color: #59261b;
  background-color: var(--mainColorLight);
  color: white;
  color: var(--textColor);
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
}

#warning {
  color: #ff3608;
  color: var(--secondairyColor);
  font-size: 0.7rem;
  text-align: center;
  margin: 0;
  text-wrap: nowrap;
}

.alert {
  padding: 15px;
  width: 90vw;
  max-width: 500px;
  margin: 15px;
  background-color: #2384d3 !important;
  border: none !important;
  color: white;
  position: fixed;
  bottom: 1rem;
  -webkit-animation-name: slidein;
          animation-name: slidein;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

@-webkit-keyframes slidein {
  from {
    bottom: -5rem;
  }
  to {
    bottom: 1rem;
  }
}

@keyframes slidein {
  from {
    bottom: -5rem;
  }
  to {
    bottom: 1rem;
  }
}

.closebtn:hover {
  color: black;
}

.alert-container h2 {
  margin: 0;
  padding-bottom: 0.2rem;
  font-size: 0.9rem;
  font-family: Arial, sans-serif;
}

.textcontainer p {
  margin: 0;
  font-size: 0.8rem;
  line-height: 1rem;
  font-family: Arial, sans-serif;
}

.alert-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}

#close-button {
  font-size: 0.8rem;
  padding-right: 0.5rem;
  text-align: center;
  font-family: Arial, sans-serif;
  cursor: pointer;
}

#cta-container {
  z-index: -1;
  background-image: url(/static/media/background.9ca6c3b5.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.cta-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  width: 100%;
  height: 100%;
}

.playbutton {
  padding: 0.8rem 1.5rem;
  margin-bottom: 5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #51cf66;
  box-shadow: 2px 2px 2px #000;
  font-size: 1rem;
  transition: 0.3s ease-in-out;
  color: black;
}

.playbutton:hover {
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  box-shadow: 4px 4px 4px #000;
}

.cta-flex h1 {
  font-size: 4rem;
  color: #fff;
  text-shadow: 3px 3px 3px #000;
  transition: 0.3s ease-in-out;
}

#character img {
  width: 250px;
  height: 100%;
  object-fit: contain;
}
* {
  color: #fff;
}
.about-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  color: #fff;
  grid-gap: 4rem;
  gap: 4rem;
  transition: 0.5s;
}

.about-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

#character-text p {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.8;
}

#aboutpage-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
}

.learnitems-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  flex-wrap: wrap;
}

.learn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  text-align: center;
}

.learn-item {
  border-top: 1px solid rgba(106, 32, 13, 0.7);
  border-bottom: 1px solid rgba(106, 32, 13, 0.7);

  padding: 1rem;
  text-align: center;
}

.learn-item p {
  font-size: 0.8rem;
  padding: 0.5rem 0;
  line-height: 1.3;
}

@media screen and (min-width: 1024px) {
  #character-text {
    width: 60%;
  }
  #character-text p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .about-container {
    flex-direction: column;
    padding: 0 3rem;
  }
  .about-container h1 {
    font-size: 1.5rem;
  }
  #character-text {
    width: 100%;
  }
  #character-text p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 368px) {
  .about-container {
    padding: 0 1rem;
  }

  .learn-item {
    width: 90%;
  }
}

#character img {
  width: 250px;
  height: 100%;
  object-fit: contain;
}
* {
  color: #fff;
}
.about-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  color: #fff;
  grid-gap: 4rem;
  gap: 4rem;
  transition: 0.5s;
}

.about-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

#character-text p {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.8;
}

#aboutpage-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
}

.learnitems-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  flex-wrap: wrap;
}

.learn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  text-align: center;
}

.learn-item {
  border-top: 1px solid rgba(106, 32, 13, 0.7);
  border-bottom: 1px solid rgba(106, 32, 13, 0.7);

  padding: 1rem;
  text-align: center;
}

.learn-item p {
  font-size: 0.8rem;
  padding: 0.5rem 0;
  line-height: 1.3;
}

@media screen and (min-width: 1024px) {
  #character-text {
    width: 60%;
  }
  #character-text p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .about-container {
    flex-direction: column;
    padding: 0 3rem;
  }
  .about-container h1 {
    font-size: 1.5rem;
  }
  #character-text {
    width: 100%;
  }
  #character-text p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 368px) {
  .about-container {
    padding: 0 1rem;
  }

  .learn-item {
    width: 90%;
  }
}

* {
  letter-spacing: 0.5px;
  line-height: 1.5;
}
/* ChatBot.css */
.ChatBot {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100%;
  font-family: Arial, sans-serif;
}

.chatContainer {
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 1px 1px 10px 5px #33140c;
  overflow: scroll;
  resize: both;
  min-width: 450px;
  max-width: 800px;
  max-height: 700px;
  min-height: 300px;
}

#ui button {
  width: 100%;
  margin-top: 5px;
}

.messageList {
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.user,
.ChatGPT {
  margin: 10px 0;
}

.user {
  align-self: flex-end;
  max-width: 80%;
  right: 0;
  background-color: #a7988b;
  padding: 10px;
  border-radius: 5px;
}

.ChatGPT {
  align-self: flex-start;
  background-color: var(--mainColorLight);
  max-width: 80%;
  padding: 10px;
  border-radius: 5px;
}

.messageInput {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.chatContainer,
.chatContainer button,
.chatContainer input {
  font-size: 0.8rem;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

/* Forum */
.forum {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  grid-gap: 30px;
  gap: 30px;
  padding: 90px;
  margin-bottom: 100px;
}

.forum h1 {
  font-size: 2rem;
}

select {
  color: black;
}

/*---------------------------------------*/
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.loginTitle {
  font-size: 24px;
  margin-top: 100px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 90px;
}

.loginForm label {
  font-size: 14px;
  margin-bottom: 5px;
}

.loginForm input[type="text"],
.loginForm input[type="password"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.loginBtn {
  padding: 8px 16px;
  background-color: #ff4500;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.loginBtn:hover {
  background-color: #ff6347;
}

.login p {
  font-size: 14px;
  margin-top: 10px;
}

.login p a {
  color: #ff4500;
  text-decoration: none;
}

.login p a:hover {
  text-decoration: underline;
}

.register {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px;
}

.registerTitle {
  font-size: 24px;
  margin-bottom: 20px;
}

.registerForm {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.registerForm label {
  font-weight: bold;
  margin-bottom: 5px;
}

.registerForm input {
  padding: 8px;
  margin-bottom: 15px;
}

.registerBtn {
  background-color: #ff4500;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.registerBtn:hover {
  background-color: #e63c00;
}

.register p {
  margin-top: 20px;
  font-size: 14px;
}

.register p a {
  color: #ff4500;
  text-decoration: none;
}

.register p a:hover {
  text-decoration: underline;
}

/*---------------------------------------*/

.create-thread {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  max-width: 700px;
}

.forumTitle {
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.forumForm {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.forum__container input,
.forum__container select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.forumBtn {
  background-color: #ff4500;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.forumBtn:hover {
  background-color: #ff6347;
}

.thread__container {
  width: 100%;
  max-width: 700px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
}

.thread__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  max-width: 600px;
  width: 100%;
}

.thread__item p {
  margin: 0;
}

.thread__item p:first-child {
  font-size: 1.2rem;
  font-weight: bold;
}

.thread__item p:last-child {
  font-size: 0.8rem;
  color: #999;
}

.react__container {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.react__container svg {
  width: 16px;
  fill: #999;
  cursor: pointer;
}

.react__container svg:hover {
  fill: #ff4500;
}

.react__container {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.react__container svg {
  width: 16px;
  fill: #999;
  cursor: pointer;
}

.react__container svg:hover {
  fill: #ff4500;
}

/* Tag Filters */

.tag-filters {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

.tag-filters label {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-size: 0.9rem;
  color: #666;
  cursor: pointer;
}
.tag-filters input[type="checkbox"] {
  -webkit-appearance: none;
          appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
}

.tag-filters input[type="checkbox"]:checked {
  border-color: #ff4500;
  background-color: #ff4500;
}

.tag-filters span {
  font-size: 0.9rem;
}

.tag-filters input[type="checkbox"]:checked::before {
  background-color: #fff;
}

.tag-filters span:hover {
  color: #ff4500;
}

/* ShowAlert Component */

.show-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  background-color: #ff6347;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.show-alert .alert-icon {
  font-size: 1.2rem;
}

.show-alert p {
  margin: 0;
  font-size: 0.9rem;
  font-weight: bold;
}

/*---------------------------------*/

.replies {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  margin-bottom: 20px; /* Added margin-bottom */
}

.repliesTitle {
  font-size: 2rem;
  margin-bottom: 20px;
}

.modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  max-width: 700px;
  padding: 50px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.modal__content label {
  font-size: 1rem;
  font-weight: bold;
}

.modalInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: black;
}

.modalBtn {
  background-color: #ff4500;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.modalBtn:hover {
  background-color: #ff6347;
}

.thread__container {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  max-width: 700px;
}

.thread__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 5px;
  gap: 5px;
  padding: 10px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.thread__item p {
  margin: 0;
  font-size: 0.9rem;
}

.react__container {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.react__container p {
  margin: 0;
  font-size: 0.8rem;
  color: #999999;
}

.react__container svg {
  width: 16px;
  cursor: pointer;
}

.react__container svg:hover {
  fill: #ff4500;
}

.container {
  display: flex;
}

.navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 200px; /* Pas de breedte aan naar wens */
  padding: 10px;
}

.navbarTitle {
  margin: 0;
  padding-bottom: 10px;
}

.tag-filters {
  display: flex;
  flex-direction: column;
}

.tag-filters label {
  margin-bottom: 5px;
}

.navbar {
  position: fixed;
  right: 0;
  top: 20px;
  z-index: 1;
  margin-top: 100px;
  margin-right: 90px;
}


.contact-page{
    box-shadow: 1px 1px 3px 2px white;
display: block;
background-color: black;
margin: 200px auto;
max-width: 500px;
max-height: 500px;
padding: 20px;




}

.contact-page input, textarea{
display: flex;
flex-direction: column; 
width: 100%;
margin: 10px auto;
padding: 10px;
border: 1px solid white
}

.message{
color: black;

}

.contact-page span{
color:#ff4500 ;


}

/* Voeg deze stijlregels toe aan je contact.css-bestand of een ander CSS-bestand waar je de stijlen definieert */

.alert {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  color: #333;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.alert-error {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

