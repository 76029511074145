@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  text-decoration: none;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
  background-color: var(--backgroundColor);
  padding: 0%;
  overflow-x: hidden;
}

:root {
  --mainColor: #33130c;
  --mainColorLight: #59261b;
  --secondairyColor: #ff3608;
  --textColor: white;
  --backgroundColor: #150704;
}

/* GAMEPLAYER */

@media screen and (min-width: 768px) {
  .game-container {
  }
}

/* Classes */

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

button {
  background-color: var(--mainColorLight);
  color: var(--textColor);
  border: none;
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
}

button:hover {
  background-color: var(--secondairyColor);
  color: var(--textColor);
}

input {
  background-color: var(--mainColorLight);
  color: var(--textColor);
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
}

#warning {
  color: var(--secondairyColor);
  font-size: 0.7rem;
  text-align: center;
  margin: 0;
  text-wrap: nowrap;
}
